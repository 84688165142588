import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        document.title = "BeckToPuur - Contact"
        return (
            <div class="container">
                <div class="row rowContent">
                    <img class="img-homePageL contentBig contatVlees" src="./img/Contact/Contact1.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/Contact/Contact1.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Werken/koken met BeckToPuur?</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Contacteer ons als u ons vlees wilt verkijgen!</h5>
                    <p class="pe-5 text-zwart left50" id='contact'>
                        Wij leveren voornamelijk aan slagers, hoevewinkels en consumenten die met duurzaam varkensvlees willen werken/koken.
                    </p>
                </div>
                <hr />
                <div class="row rowContent">
                    <img class="img-homePageR contentBig" src="./img/Contact/Contact2.jpeg" alt="first foto" />
                    <img class="img-homePageR contentSmall" src="./img/Contact/Contact2.jpeg" alt="first foto" />
                    <h3 class="mt-4 text-secondary">Contact gegevens</h3>
                    <p class="text-zwart"><i class="fas fa-home me-3"></i> Hoogstraten en Rijkevorsel</p>
                    <p class="text-zwart">
                        <a href="mailto:info@becktopuur.be" class="text-zwart">
                            <i class="fas fa-envelope me-3"></i>
                            info@becktopuur.be
                        </a>
                    </p>
                    <h5 class="mt-4 text-secondary">Vul dit formulier in en wij nemen contact met u op</h5>
                    <p class="pe-5 text-zwart contactForm">
                        <form action="/api/formulierContact.php" method='post'>
                            <div class="mb-3">
                                <label for="name" class="form-label">Naam:</label>
                                <input type="text" id="name" name="name" class="form-control" />
                            </div>
                            <div class="mb-3">
                                <label for="email">Email:</label>
                                <input type="email" id="email" name="email" class="form-control" />
                            </div>
                            <div class="mb-3">
                                <label for="bericht">Bericht:</label>
                                <textarea class="form-control" id="bericht" name='bericht' rows="3"></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary">Verzenden</button>
                        </form>
                    </p>
                </div>
            </div>
        )
    }
}
