import React, { Component } from 'react'

export default class DuurzaamOndernemen extends Component {
    render() {
        document.title = "BeckToPuur - Duurzaam ondernemen"
        return (
            <div class="container">
                <div class="row rowContent">
                    <img class="img-homePageL contentBig" src="./img/DuurzaamOndernemen/duurzaam1.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/DuurzaamOndernemen/duurzaam1.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Kringloopdier</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Recycleren en herwaarderen</h5>
                    <p class="pe-5 text-zwart left50">
                        BeckToPuur varkens zijn authentieke kringloopdieren. Ze kunnen recycleren en herwaarderen. Hun dieet bestaat uit restproducten van de humane voeding
                        die niet meer geschikt zijn voor menselijke consumptie maar een lekkernij zijn voor onze varkens. Op het menu staan onder andere:
                    </p>
                    <p class="pe-5 text-zwart left50">
                        <ul>
                            <li>Aardappelpuree en frieten</li>
                            <li>Biergist</li>
                            <li>Tarwezetmeel</li>
                            <li>Tarwegistconcentraat (bio-ethanol)</li>
                            <li>Brood</li>
                            <li>Cichoreipulp</li>
                        </ul>
                    </p>
                    <p class="pe-5 text-zwart left50">
                        Bovenstaande producten zijn vaak vloeibaar, waardoor we naast gerecupereerd regenwater slechts beperkt grondwater oppompen.
                    </p>
                </div>
                <div class="row rowContent">
                    <img class="img-homePageR contentBig" src="./img/DuurzaamOndernemen/kringloop.jpg" alt="first foto" />
                    <img class="img-homePageR contentSmall" src="./img/DuurzaamOndernemen/kringloop.jpg" alt="first foto" />
                    {/* <h3 class="mt-4 text-secondary">Emissie, energie en CO2-captatie</h3>
                    <span class="borderTitel"></span> */}
                    <h5 class="mt-4 text-secondary">BeckToPuur varken: onmisbaar in de voedingskringloop</h5>
                    <p class="pe-5 text-zwart">
                        De mest van onze varkens wordt deels aangewend op lokale gronden en vormt de basis voor gezonde gewassen.
                        Op onze gronden telen we ondermeer mais en tarwe die we samen met bovenstaande producten mengen tot een heerlijke "brij".
                    </p>
                    <p class="pe-5 text-zwart">
                        Verder hebben we aardappelen en suikerbieten in het teeltplan die worden geleverd aan de voedingsindustrie. 
                        De reststromen van deze producten komen terug als lekkernij voor onze varkens (zie boven).
                    </p>
                </div>
                <hr />
                <div class="row rowContent">
                    <img class="img-homePageL contentBig" src="./img/DuurzaamOndernemen/duurzaam2.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/DuurzaamOndernemen/duurzaam2.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Emissie, energie en CO2-captatie</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Voorloper in de uitvoering van de Europese Green Deal</h5>
                    <p class="pe-5 text-zwart left50">
                        Wij beperken onze CO2-uitstoot door het gebruik van fossiele brandstoffen af te bouwen en te vervangen door duurzame 
                        en/of hernieuwbare vormen van energie. De voorbije jaren investeerden we in zonnepanelen en verwarming op resthoutsnippers.
                    </p>
                    <p class="pe-5 text-zwart left50">
                        Door middel van luchtwassers en biobedden op uitgaande stallucht wordt meer dan 70% van uitstootemissies gereduceerd.
                    </p>
                    <p class="pe-5 text-zwart left50">
                        Een aanplant van fruitbomen, hoogstambomen en houtkanten zorgen voor CO2-captatie en een stimulering van de biodiversiteit 
                        rondom de stallen.
                    </p>
                </div>
                <hr />
                <div class="row rowContent">
                    <img class="img-homePageR contentBig" src="./img/DuurzaamOndernemen/duurzaam3.jpg" alt="first foto" />
                    <img class="img-homePageR contentSmall" src="./img/DuurzaamOndernemen/duurzaam3.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary">Mensen maken het verschil</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary">Zorgzaam en hecht team</h5>
                    <p class="pe-5 text-zwart">
                        Varkens vragen 365 dagen per jaar zorg, toewijding, flexibiliteit en vakkennis. Als 
                        familiale boerderij, aangevuld met een team vaste medewerkers investeren we in opleiding en peoplemanagement. 
                    </p>
                    <p class="pe-5 text-zwart">
                        Want bij het houden van BeckToPuur varkens, maken mensen het verschil. 
                    </p>
                </div>
            </div>
        )
    }
}
