import React from 'react'

export default function FooterBotttem() {
  return (
    <footer class="text-center text-lg-start text-muted mt-5">
        {/* <!-- Section: Social media --> */}
        <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
            {/* <!-- Left --> */}
            <div class="me-5 text-secondary d-none d-lg-block">
                <span>Volg ons op sociale media:</span>
            </div>
            {/* <!-- Left --> */}
    
            {/* <!-- Right --> */}
            <div>
                <a href="https://www.facebook.com/becktopuurvarkensvlees" class="me-4 text-secondary">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/becktopuur" class="me-4 text-secondary">
                    <i class="fab fa-instagram"></i>
                </a>
                {/* <a href="/linkedin" class="me-4 text-secondary">
                    <i class="fab fa-linkedin"></i>
                </a> */}
            </div>
            {/* <!-- Right --> */}
        </section>
        {/* <!-- Section: Social media --> */}
    
        {/* <!-- Section: Links  --> */}
        <section class="">
            <div class="container text-center text-md-start mt-5">
                {/* <!-- Grid row --> */}
                <div class="row mt-3">
                    {/* <!-- Grid column --> */}
                    <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                        {/* <!-- Content --> */}
                            <img class="img-logo-footer" src="./img/logo.png" alt="logo BeckToPuur" />
                    </div>
                    {/* <!-- Grid column --> */}
    
                    {/* <!-- Grid column --> */}
                    <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                        {/* <!-- Links --> */}
                        <h6 class="text-uppercase text-secondary fw-bold mb-4">
                            Meer info
                        </h6>
                        <p>
                            <a href="index" class="text-zwart">Home</a>
                        </p>
                        <p>
                            <a href="OnsVerhaal" class="text-zwart">Ons verhaal</a>
                        </p>
                        <p>
                            <a href="varkens" class="text-zwart">BeckToPuur varkens</a>
                        </p>
                        <p>
                            <a href="DuurzaamOndernemen" class="text-zwart">Duurzaam ondernemen</a>
                        </p>
                        <p>
                            <a href="contact" class="text-zwart">Werken/koken met BeckToPuur?</a>
                        </p>
                    </div>
                    {/* <!-- Grid column --> */}
    
                    {/* <!-- Grid column --> */}
                    <div class="col-md-4 text-zwart col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                        {/* <!-- Links --> */}
                        <h6 class="text-uppercase text-secondary fw-bold mb-4">
                            Contact
                        </h6>
                        <p><i class="fas fa-home me-3"></i> Hoogstraten en Rijkevorsel</p>
                        <p>
                            <a href="mailto:info@becktopuur.be" class="text-zwart">
                                <i class="fas fa-envelope me-3"></i>
                                info@becktopuur.be
                            </a>
                        </p>
                    </div>
                    {/* <!-- Grid column --> */}
                </div>
                {/* <!-- Grid row --> */}
            </div>
        </section>
        {/* <!-- Section: Links  --> */}
    </footer>
  )
}
