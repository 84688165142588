import React, { Component } from 'react'

export default class Varkens extends Component {
    render() {
        document.title = "BeckToPuur - Varkens"
        return (
            <div class="container">
                <div class="row rowContent">
                    <img class="img-homePageL contentBig" src="./img/VarkensPage/varken1.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/VarkensPage/varken1.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Belgisch kwaliteitslabel</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Garantie voor kwaliteit</h5>
                    <p class="pe-5 text-zwart left50">
                        Wij zorgen ervoor dat BeckToPuur varkens zich goed voelen. Zo kan u genieten van gezond en lekker varkensvlees. Onze aanpak 
                        werd bekroond met de labels CoDiPlan+, Certus en Bepork.
                    </p>
                    <p class="pe-5 text-zwart left50">
                        Deze kwaliteitslabels staan onder toezicht van het Federaal Agentschap voor Voedselveiligheid. Ze bieden boven op de 
                        wettelijke basis voor veilig en kwaliteitsvol varkensvlees nog extra garanties inzake dierenwelzijn, duurzaamheid en gezondheid.
                    </p>
                </div>
                <hr />
                <div class="row rowContent">
                    <img class="img-homePageR contentBig" src="./img/VarkensPage/varken2.jpg" alt="first foto" />
                    <img class="img-homePageR contentSmall" src="./img/VarkensPage/varken2.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary">Diergezondheid</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary">Hygiënebeleid</h5>
                    <p class="pe-5 text-zwart">
                        Om onze varkens gezond te houden en te beschermen voor mogelijke virussen en bacteriën hanteert BeckToPuur een streng 
                        gezondheidsbeleid. Via speeksel en bloedanalyses gebeurt er op regelmatige basis een gezondheidsmonitoring van het hele varkensbedrijf.
                        Aan de hand van deze resultaten worden gerichte vaccinaties ingezet. Vaccinaties in combinatie met strakke protocollen
                        (reinigen/ontsmetten, interne en externe biosecurity, ...) zorgen er voor dat antibioticagebruik tot een minimum wordt herleid.
                    </p>
                </div>
                <hr />
                <div class="row rowContent">
                    <img class="img-homePageL contentBig" src="./img/VarkensPage/varken3.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/VarkensPage/varken3.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Dierwelzijn</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Het hart van ons bedrijf</h5>
                    <p class="pe-5 text-zwart left50">
                        Onze varkens zijn letterlijk het kloppend hart van ons bedrijf. Moderne huisvesting, 
                        natuurlijk daglicht, optimale voer- en watervoorziening zorgen er voor dat het aangenaam vertoeven is in onze stallen. 
                        Aangepaste speelmaterialen per diercategorie zorgen dat er nooit een moment van verveling ontstaat.
                    </p>
                </div>
            </div>
        )
    }
}
