import React from "react";
import { Switch, Route, Redirect } from "react-router";
import ReactGA from 'react-ga4';

import Navbar from "./Navbar";
import BigHeader from "./BigHeader";
import FooterBotttem from "./FooterBottem";

import Home from "./Pages/Home";
import OnsVerhaal from "./Pages/OnsVerhaal";
import NotFound from "./Pages/NotFound";
import Varkens from "./Pages/Varkens";
import DuurzaamOndernemen from "./Pages/DuurzaamOndernemen";
import Contact from "./Pages/Contact";
import ContactSend from "./ContactSend";

ReactGA.initialize('G-WF8LXZWG61', {
  debug: false,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});

function App() {
  // ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA.send(window.location.pathname + window.location.search);
  return (
    <div className="App">
      <Navbar />
      <BigHeader />
      <Route path="/contactSend" component={ContactSend} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/index" component={Home} />
        <Route path="/404" component={NotFound} />
        <Route path="/OnsVerhaal" component={OnsVerhaal} />
        <Route path="/varkens" component={Varkens} />
        <Route path="/DuurzaamOndernemen" component={DuurzaamOndernemen} />
        <Route path="/contact*" component={Contact} />
        <Redirect to="/404" />
      </Switch>
      <FooterBotttem />
    </div>
  );
}

export default App;
