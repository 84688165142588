import React, { Component } from 'react'

export default class OnsVerhaal extends Component {
    render() {
        document.title = "BeckToPuur - Ons verhaal"
        return (
            <div class="container">
                <div class="row rowContent">
                    <img class="img-homePageL contentBig onsVerhaalVlees" src="./img/OnsVerhaal/onsVerhaal1.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/OnsVerhaal/onsVerhaal1.jpg" alt="first foto" />
                    <h3 class="mt-4 text-secondary left50">Puur en duurzaam varkensvlees recht van de boer</h3>
                    <span class="borderTitel"></span>
                    <h5 class="mt-4 text-secondary left50">Lekker, lokaal en authentiek</h5>
                    <p class="pe-5 text-zwart left50">
                        BeckToPuur varkens worden geboren op het bedrijf van Dries en Ilse Beck-Vanthillo in Meer. Op hun bedrijf proberen ze een omgeving te
                        creëren waar varkens zich thuis voelen. Dierenwelzijn en duurzaamheid vormen een rode draad op hun bedrijfsvoering.
                    </p>
                    <p class="pe-5 text-zwart left50">
                        De biggen worden zowel op hun eigen bedrijf als op het bedrijf van Ben en Martine Bruneel-Cox verder gehouden tot slachtrijp
                        vleesvarken. Beide gezinnen zijn overtuigd  dat dieren die zich goed voelen, gezond en lekker vlees kunnen produceren. 
                    </p>
                    <p class="pe-5 text-zwart left50">
                        In 2022 is uit de samenwerking van beide bedrijven het varkenskwaliteitslabel "BeckToPuur" onstaan.
                        BeckToPuur wil nu en in de toekomst de garantie bieden aan slagers en consumenten op puur, smakelijk en duurzaam varkensvlees.
                    </p>
                </div>
                <div class="row rowContent">
                    <img class="img-homePageL contentBig onsVerhaalBruneel" src="./img/OnsVerhaal/onsVerhaal2New.jpg" alt="first foto" />
                    <img class="img-homePageL contentSmall" src="./img/OnsVerhaal/onsVerhaal2New.jpg" alt="first foto" />
                    <img class="img-content" src="./img/OnsVerhaal/onsVerhaal3.jpg" alt="first foto" />
                    <img class="img-homePageR contentBig" src="./img/OnsVerhaal/onsVerhaal4.png" alt="first foto" />
                    <img class="img-homePageR contentSmall" src="./img/OnsVerhaal/onsVerhaal4.png" alt="first foto" />
                </div>
            </div>
            )
    }
}
