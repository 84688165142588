import React, { Component } from 'react'

export class NotFound extends Component {
  render() {
    document.title = "BeckToPuur - Page not found"
    return (
      <h1>404 Page not found</h1>
    )
  }
}

export default NotFound