import React, { useEffect } from 'react'

function Home() {
    useEffect(() => {
        document.title = "BeckToPuur - Home"
    }, []);
    return (
    <div class="container">
        <div class="row rowContent">
            <img class="img-homePageR contentBig" src="./img/Home/ons.jpeg" alt="first foto" />
            <img class="img-homePageR contentSmall" src="./img/Home/ons.jpeg" alt="first foto" />
            <h3 class="mt-4 text-secondary">Ons verhaal</h3>
            <span class="borderTitel"></span>
            <h5 class="mt-4 text-secondary">Puur en duurzaam varkensvlees, recht van de boer</h5>
            <p class="pe-5 text-zwart">
                BeckToPuur varkens worden geboren op het bedrijf van Dries en Ilse Beck-Vanthillo in Meer. Op hun bedrijf creëren ze een 
                omgeving waar varkens zich thuis voelen. De biggen kunnen zowel op hun eigen bedrijf als op het bedrijf van Ben en Martine 
                Bruneel-Cox verder groeien tot vleesvarken. Dierenwelzijn en duurzaamheid vormen bij beide gezinnen een rode draad in de bedrijfsvoering.
            </p>
            <a class="meerInfo text-background bg-secondary cursor-pointer" href="OnsVerhaal" rel="Ons verhaal" title="Ons verhaal"
                data-placement="bottom" role="button">
                Kom meer te weten over ons
            </a>
        </div>
        <hr />
        <div class="row rowContent">
            <img class="img-homePageL contentBig" src="./img/Home/IMG_20220316_111605.jpg" alt="first foto" />
            <img class="img-homePageL contentSmall" src="./img/Home/IMG_20220316_111605.jpg" alt="first foto" />
            <h3 class="mt-4 text-secondary left50">BeckToPuur varkens</h3>
            <span class="borderTitel"></span>
            <h5 class="mt-4 text-secondary left50">BeckToPuur varkens zijn het hart van ons bedrijf</h5>
            <p class="pe-5 text-zwart left50">
                Een streng hygiënebeleid, zorgvuldige huisvesting, intensieve opvolging van diergezondheid en een passie voor 
                dieren zorgen ervoor dat BeckToPuur varkens zich goed voelen. En varkens die zich goed voelen hebben een uitmuntende vleeskwaliteit.
            </p>
            <a class="meerInfo text-background bg-secondary cursor-pointer" href="varkens" rel="Ons verhaal"
                title="Ons verhaal" data-placement="bottom" role="button">
                Lees meer over onze varkens
            </a>
        </div>
        <hr />
        <div class="row rowContent">
            <img class="img-homePageR contentBig" src="./img/Home/kringloop.jpg" alt="first foto" />
            <img class="img-homePageR contentSmall" src="./img/Home/kringloop.jpg" alt="first foto" />
            <h3 class="mt-4 text-secondary">Duurzaam ondernemen</h3>
            <span class="borderTitel"></span>
            <h5 class="mt-4 text-secondary">Voorloper in de uitvoering van de Europese Green Deal</h5>
            <p class="pe-5 text-zwart">
                Naast aandacht voor onze varkens willen we een voorloper zijn in de implementatie van de Europese “green deal” 
                en beperken we onze ecologische voetafdruk. Luchtwassers en biobedden op uitgaande stallucht reduceren onze 
                stikstofuitstoot met meer dan 70%. Aanplant van fruitbomen, hoogstambomen en houtkanten zorgen voor CO2 captatie 
                en stimuleren de biodiversiteit rondom de stallen.
            </p>
            <a class="meerInfo text-background bg-secondary cursor-pointer" href="DuurzaamOndernemen" rel="Ons verhaal"
                title="Ons verhaal" data-placement="bottom" role="button">
                Meer weten
            </a>
        </div>
        <hr />
        <div class="row rowContent">
            <img class="img-homePageL contentBig" src="./img/Home/Foto vlees.jpg" alt="first foto" />
            <img class="img-homePageL contentSmall" src="./img/Home/Foto vlees.jpg" alt="first foto" />
            <h3 class="mt-4 text-secondary left50">Ontdek ons</h3>
            <span class="borderTitel"></span>
            <h5 class="mt-4 text-secondary left50">Kiezen voor lekker, lokaal en duurzaam varkensvlees</h5>
            <p class="pe-5 text-zwart left50">
                BeckToPuur zorgt voor een eerlijk, authentiek stukje vlees op je bord, recht van de boer. Onze varkens worden in België gekweekt met respect voor mens, dier en milieu.
            </p>
            <a class="meerInfo text-background bg-secondary cursor-pointer" href="contact" rel="Ons verhaal"
                title="Ons verhaal" data-placement="bottom" role="button">
                Werken/koken met BeckToPuur?
            </a>
          </div>
    </div>
  )
}

export default Home