import React from 'react'

export default function Navbar() {
  return (
    <nav class="navbar navbar-expand-xl z-index-3 py-3">
        <div class="container">
            <button class="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation"
                aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon mt-2">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </span>
            </button>
            <a class="navbar-brand text-background" href="index" rel="tooltip"
                title="BeckToPuur" data-placement="bottom">
                BeckToPuur
            </a>
            <div class="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0" id="navigation">
                <ul class="navbar-nav navbar-nav-hover mx-auto">
                      
                    <li class="nav-item mx-2">
                        <a class="nav-link text-background ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                            role="button"
                            href="index">
                            Home
                        </a>
                      </li>
                      
                    <li class="nav-item mx-2">
                        <a class="nav-link text-background ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                            role="button"
                            href="OnsVerhaal">
                            Ons verhaal
                        </a>
                    </li>

                    <li class="nav-item mx-2">
                        <a class="nav-link text-background ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                            role="button"
                            href="varkens">
                            BeckToPuur Varkens
                        </a>
                    </li>
                    <li class="nav-item mx-2">
                        <a class="nav-link text-background ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                            role="button"
                            href="DuurzaamOndernemen">
                            Duurzaam ondernemen
                        </a>
                    </li>

                    <li class="nav-item dropdown dropdown-hover mx-2">
                        <a class="nav-link text-background ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                            role="button"
                            href="contact">
                            Werken/koken met BeckToPuur?
                        </a>
                    </li>

                    <li class="nav-item dropdown dropdown-hover mx-2">
                        <a class="nav-link text-background ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                            role="button" href="contact#contact">
                            Contact
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}
